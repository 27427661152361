import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { compact, map, filter, get } from 'lodash';
import classnames from 'classnames';
import { graphql, PageProps } from 'gatsby';
import path from 'path-browserify';

import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import Tag from '../../components/Tag';
import TileCard from '../../components/TileCard';
import HeroAlt from '../../components/HeroAlt';
import InfiniteScroll from '../../components/InfiniteScroll';
import SEO from '../../components/SEO/SEO';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { BlogPostsQuery, BlogFragment } from '../../../graphql.schema';
import { GlobalPageContext, PaginationContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './blog-index.module.scss';

type BlogIndexContextType = GlobalPageContext & PaginationContext;

const BlogIndex: React.FC<PageProps<BlogPostsQuery, BlogIndexContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const allBlogPostsWithCategories: BlogFragment[] = get(data, 'strapi.allBlogPostsWithCategories', []);
  const [firstOfPageSet] = allBlogPostsWithCategories;
  const pages = get(data, 'strapi.pages', []);
  const intl = useIntl();
  const [list, setList] = useState<BlogFragment[]>(allBlogPostsWithCategories);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<BlogFragment[]>([]);

  const uniqueCategories = [
    ...new Set(
      map(compact(allBlogPostsWithCategories), ({ categories }) => map(compact(categories), ({ name }) => name)).flat(),
    ),
  ];

  useEffect(() => {
    setList(allBlogPostsWithCategories.slice(1, 11));
    setIsInitialLoadDone(true);
  }, [allBlogPostsWithCategories]);

  useEffect(() => {
    let filtered: BlogFragment[] = [];
    if (selectedCategories.length > 0) {
      filtered = [...getFilteredBlogList().slice(0, 10)];
    }
    setFilteredBlogs(filtered);
  }, [selectedCategories]);

  const getFilteredBlogList = () =>
    filter(compact(allBlogPostsWithCategories), (blog) =>
      compact(blog.categories).some((cat) => selectedCategories.includes(cat.name ?? '')),
    );

  const handleCategoryClick = (tag: string) => {
    let newSelectedTags: string[] = [];
    if (selectedCategories.includes(tag)) {
      newSelectedTags = selectedCategories.filter((e) => e !== tag);
      setSelectedCategories([...new Set(newSelectedTags)]);
    } else {
      newSelectedTags = [...selectedCategories, tag];
      setSelectedCategories([...new Set(newSelectedTags)]);
    }
  };
  if (!websiteLocale)
    return (
      <LayoutWrapper {...pageContext}>
        <p>Could not find any blog posts</p>
      </LayoutWrapper>
    );

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ?? intl.formatMessage({ id: 'seo.blog.title' })}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.pageSettings?.metaDescription ?? intl.formatMessage({ id: 'seo.blog.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className={classnames('blog-post', styles.root)}>
        <div className="container">
          {firstOfPageSet && (
            <div className="section">
              <HeroAlt
                linkText={intl.formatMessage({ id: 'blogCardText', defaultMessage: 'Read article' })}
                date={firstOfPageSet.originalDate ?? firstOfPageSet.publishedAt}
                image={firstOfPageSet.image?.url}
                title={firstOfPageSet.title}
                tags={compact(firstOfPageSet.categories).map((cat) => cat.name)}
                variant="blog"
                slug={path.join(pageContext.pathPrefixes.blog, firstOfPageSet.slug ?? '')}
                excerpt={firstOfPageSet.excerpt ?? ''}
              />
            </div>
          )}
          {uniqueCategories.length ? (
            <div className={styles.filters}>
              <div className="content">
                <h5 className="title brand-a">Categories</h5>
                <p className="tag-list is-text-1">
                  {map(uniqueCategories, (name, index) => (
                    <Tag
                      isActive={selectedCategories?.includes(name)}
                      onClick={() => handleCategoryClick(name)}
                      isClickable={true}
                      name={name}
                      key={`${name}${index}`}
                    />
                  ))}
                </p>
              </div>
            </div>
          ) : null}
          <section className={styles.gridContainer}>
            {selectedCategories.length > 0
              ? filteredBlogs.map(({ documentId, image, title, originalDate, publishedAt, slug, excerpt, categories }) => (
                  <TileCard
                    linkText={intl.formatMessage({ id: 'blogCardText', defaultMessage: 'Read article' })}
                    slugBase={pageContext.pathPrefixes.blog}
                    key={`blog-post-${documentId}`}
                    image={image?.url}
                    short={excerpt ?? ''}
                    slug={slug ?? ''}
                    title={title}
                    date={originalDate ?? publishedAt}
                    variant="blog"
                    categories={compact(categories)}
                    selectedCategories={selectedCategories}
                  />
                ))
              : map(list, ({ documentId, image, title, originalDate, publishedAt, slug, excerpt, categories }) => (
                  <TileCard
                    linkText={intl.formatMessage({ id: 'blogCardText', defaultMessage: 'Read article' })}
                    slugBase={pageContext.pathPrefixes.blog}
                    key={`blog-post-${documentId}`}
                    image={image?.url}
                    short={excerpt ?? ''}
                    slug={slug ?? ''}
                    title={title}
                    date={originalDate ?? publishedAt}
                    variant="blog"
                    categories={compact(categories)}
                    selectedCategories={selectedCategories}
                  />
                ))}
          </section>
          {isInitialLoadDone && (
            <InfiniteScroll
              selectedCategories={selectedCategories}
              categoryList={getFilteredBlogList()}
              filteredList={filteredBlogs}
              completeList={allBlogPostsWithCategories}
              list={list}
              setList={(list) => setList(list)}
              setFilteredList={(filteredList) => setFilteredBlogs(filteredList)}
            />
          )}
        </div>
      </div>
      <div className={classnames('page-components')}>
        {pages[0]?.components &&
          pages[0]?.components?.map((component: any, idx: any) =>
            component ? (
              <ComponentRenderer
                hasRTL={websiteLocale?.hasRightToLeftLanguage}
                component={component}
                key={`page-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
      </div>
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
query BlogPosts($websiteLocaleId: ID!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    allBlogPostsWithCategories: blogPosts(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}}
      sort: "original_date:desc"
      locale: $locale
      pagination: {limit: 100}
    ) {
      data {
        documentId
        attributes {
          localizations {
            data {
              attributes {
                locale
                slug
              }
            }
          }
          ...Blog
        }
      }
    }
    pages(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}, index_page: {eq: "Blogs"}}
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          localizations {
            data {
              attributes {
                locale
                slug
              }
            }
          }
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(BlogIndex));
